@media only screen and (max-width: 767px) {
    .slicknav_menu {
        display: block !important;
    }

    .container {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    #logo {
        padding: 15px 0 !important;
        width: 150px !important;
    }

    .slide {
        height: 300px !important;
    }

    .header-top-wrap {
        padding-top: 10px 0 !important;
    }

    .testimonials {
        height: auto !important;
        margin-top: 30px !important;
    }

    .payment_panel {
        padding: 35px 15px 10px !important;
    }

    .payment_panel label {
        font-size: 20px !important;
    }

    .contact_text {
        margin-top: 30px;
    }

    .home_row_3 .col-sm-7 .home_text {
        height: auto !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .mainmenu-menu li a {
        padding: 36px 7px 38px !important;
    }

    #logo {
        padding-top: 30px !important;
    }

    .footer-menu li {
        width: 100% !important;
        margin-right: 0 !important;
    }

    .payment_panel label {
        font-size: 16px !important;
    }

    .payment_panel {
        padding: 35px 15px 10px !important;
    }

    .payment_panel .fa {
        font-size: 22px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1185px) {
    .container {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .payment_panel {
        padding: 35px 15px 10px !important;
    }
}