/*
    font-family: 'Oswald', sans-serif;
    font-family: 'Open Sans', sans-serif;
*/

* {
	outline: none !important;
}

.slicknav_menu {
    display: none;
}

body {
	background: #20232d;
    color: #636363;
	font-family: 'Open Sans', sans-serif;
}

.header {
	background: url('../images/header_bg.gif') repeat 0 0;
}
.mainmenu-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.mainmenu-menu>li {
    position: relative;
    display: inline-block;
    margin: 0 -2px;
}
.mainmenu-menu li a {
    font-family: 'Oswald', sans-serif;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 14px;
    display: inline-block;
    line-height: 16px;
    position: relative;
    text-decoration: none!important;
    padding: 36px 19px 38px 18px;
    z-index: 100;
    background: url(../images/tail-item.png) repeat-x 0 92px;
}
.mainmenu-menu>li:hover>a,
.mainmenu-menu>li.sfHover>a,
.mainmenu-menu>li.active>a {
    background: url(../images/tail-item.png) repeat-x 0 0;
    color: #0dd1fb;
}
.mainmenu-menu li:hover ul,
.mainmenu-menu li.sfHover ul {
    top: 92px;
    left: 0px;
    z-index: 20!important;
}
.mainmenu-menu li:hover li ul,
ul.mainmenu-menu li.sfHover li ul {
    top: -999em
}
.mainmenu-menu li li {
    position: relative;
    float: none;
    display: block;
    padding: 7px 0 7px 19px;
    border-top: 1px solid #2f3034
}
.mainmenu-menu li li.first {
    border: none;
}
.mainmenu-menu li li a {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 16px;
    text-decoration: none;
}
.mainmenu-menu ul {
    background: #15171b;
    position: absolute;
    top: -999em;
    left: 0;
    display: none;
    width: 156px;
    margin: 0;
    padding: 0;
    padding: 10px 2px;
}
.mainmenu-menu>li ul li a:hover,
.mainmenu-menu>li>ul>li.sfHover>a {
    color: #0dd1fb
}
.mainmenu-menu li li:hover ul,
ul.mainmenu-menu li li.sfHover ul {
    left: 180px;
    top: -2px;
}

#logo {
	padding-top: 25px;
	display: block;
}

#logo img {
	max-width: 100%;
	height: auto;
}

.footer {
	background: url('../images/footer_bg.gif');
}

.footer-inner {
	padding: 45px 0 0 0;
}

.footer_li {
	margin-bottom: 30px;
}

.footer_title {
	color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-family: "Oswald",sans-serif;
    font-weight: normal;
    margin: 14px 0 20px 0;
    padding: 0;
    text-transform: uppercase;
}

.footer_text {
	color: #b4b4b4;
    font-size: 13px;
}

.footer_text p {
	margin: 0;
	padding: 0;
	font-size: 13px;
	line-height: 20px;
}

.footer-menu {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.footer-menu li {
	display: block;
    float: left;
    margin-bottom: 3px;
    margin-right: 2%;
    width: 48%;
}

.footer-menu li a {
	color: #b4b4b4 !important;
	font-size: 13px;
}

.footer_border {
	height: 1px;
	background: #696b72;
}

.footer_bottom {
	color: #c6cacd;
    font-size: 12px;
    line-height: 14px;
    padding: 30px 0;
}

.footer_bottom a {
	color: #c6cacd !important;
}

#footer_logo {
	margin-bottom: 25px;
	max-width: 120px;
	height: auto;
}

.subpage {
	background: #fff;
}

.subpage-inner {
	padding: 60px 0;
}

.footer_form_row input[type="text"], .footer_form_row input[type="email"] {
	color:#c6cacd;
	font-size:12px;
	margin:0;
	outline:none;
	resize:none;
	position:relative;
	-webkit-background-clip:padding-box;
	border:none;
	box-shadow: none;
	background:#43454b;
	padding:7px 9px 7px;
	line-height:18px;
	border-radius:6px;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 10px;
	width: 100%;
}

.footer_form_row textarea {
	color:#c6cacd;
	font-size:12px;
	margin:0;
	outline:none;
	resize:none;
	position:relative;
	-webkit-background-clip:padding-box;
	border:none;
	box-shadow: none;
	background:#43454b;
	padding:7px 9px 7px;
	line-height:18px;
	border-radius:6px;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 10px;
	width: 100%;
	height: 100px;
	resize: none;
}

.button_1 {
	color:#4f5159;
	font-size:11px;
	line-height:16px;
	background:url('../images/button_bg.gif') repeat-x 0 0 #73797c;
	display:inline-block;
	padding:7px 11px 7px 10px;
	border-radius:4px;
	-moz-border-radius:4px;
	-webkit-border-radius:4px;
	text-decoration:none;
	border: none;
	border-radius:6px;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	box-shadow: 0px 1px 1px #404248;
	-moz-box-shadow: 0px 1px 1px #404248;
	-webkit-box-shadow: 0px 1px 1px #404248;
	margin-bottom:2px;
	font-weight:bold;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	cursor: pointer;
}

.button_1:hover {
	text-decoration:none;
	background-position: 0 bottom;
	color:#fff
}

.title_1 {
    font-size: 24px;
    line-height: 26px;
    margin: 0 0 20px 0;
    padding: 0;
    font-family: "Oswald",sans-serif;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
}

.text_1 {
	/*color: #636363;*/
}

.text_1 p {
	font-size: 14px;
	line-height: 28px;
	margin: 0 0 15px 0;
	padding: 0;
}

.order_row label {
	display: block;
	font-size: 13px;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
}

.bxslider {
	margin: 0;
	padding: 0;
}

.form_text {
	border: 1px solid #b6bac1;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
    color: #636363;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 25px;
    padding: 10px 15px 10px 40px;
    width: 100%;
    background: #fff;
}

.form_textarea {
	border: 1px solid #b6bac1;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
    color: #636363;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 25px;
    padding: 10px 15px 10px 15px;
    width: 100%;
    background: #fff;
	height: 150px;
	resize: none;
}

.form_select {
	border: 1px solid #b6bac1;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
    color: #636363;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 35px;
    padding: 10px 25px;
    width: 100%;
    background: #fff;
}

.form_text::before {
	content: '\f007';
	/*position: absolute;
	top: 50px;
	left: 50px;*/
	font-size: 13px;
	color: red;
}

.input_wrapper {
	position: relative;
}

.input_wrapper .fa {
	position: absolute;
	top: 12px;
	left: 15px;
}

.button_2 {
	background: #0dd1fb;
	color: #fff !important;
	font-size: 16px;
	font-family: "Oswald",sans-serif;
	display: inline-block;
	cursor: pointer;
	padding: 12px 25px;
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: none;
    text-decoration: none !important;
}

.button_2:hover {
	background: #636363;
}

.footer_form_row .button_2 {
	padding: 6px 25px;
}

.fa_select_arrow {
	left: auto !important;
	right: 15px;
}

.select_wrapper {
	width: 100%;
	background: url('../images/dropdown_bg.png') no-repeat right 15px center #fff;
	border: 1px solid #b6bac1;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
    height: 41px;
    margin: 0 0 25px 0;
    overflow: hidden;
}

.select_wrapper select {
    background: transparent none repeat scroll 0 0;
    border: 0 none;
    height: 41px;
    line-height: 1;
    width: 120%;
    color: #636363;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 15px;
}

.footer-wrap {
	margin-bottom: 35px;
}

.title_2 {
	display: block;
	font-size: 15px;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-weight: 600;
	margin: 0 0 30px 0;
	padding: 0;
}

.title_2 .fa {
	font-size: 16px;
	margin: 0 6px;
}

.order_form_border_div {
	margin: 25px 0 45px 0;
}

.order_form_border {
	height: 1px;
	background: #b6bac1;
}

.payment_panel {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
    background: #fff none repeat scroll 0 0;
    border: 1px solid #b6bac1;
    color: #636363;
    font-family: "Open Sans",sans-serif;
    font-size: 13px;
    font-weight: 600;
    height: 104px;
    margin-bottom: 25px;
    padding: 35px 61px 10px 74px;
    position: relative;
    width: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.payment_panel label {
	display: inline;
    font-size: 22px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.payment_panel .fa {
	font-size: 28px;
	margin-right: 10px;
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.payment_panel:hover, .payment_panel_active {
	border-color: #0dd1fb;
}

.payment_panel:hover .fa, .payment_panel_active .fa {
	color: #0dd1fb;
}

.payment_panel:hover label, .payment_panel_active label {
	color: #0dd1fb;
}

.order_form a {
	color: #0dd1fb !important;
}

.checkbox_wrapper {
	margin-bottom: 25px;
}

.order_form {
	margin-top: 50px;
}

.parsley-errors-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	position: absolute;
	top: -20px;
	right: 0;
	font-size: 12px;

}

.parsley-errors-list li {
	color: red;
	font-size: 11px;
}

.message_div {
	margin-bottom: 25px;
}

.header-top-wrap {
	padding: 15px 0;
}

#topmenu ul {
	margin: 0 -15px 0 0;
	padding: 0;
	list-style-type: none;
}

#topmenu ul li {
	display: inline;
	margin-left: 20px;
}

#topmenu ul li a {
	display: inline-block;
	font-size: 13px;
	color: #b4b4b4 !important;
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-decoration: none !important;
}

#topmenu ul li a:hover, #topmenu li.active a {
	color: #0dd1fb !important;
}

.payment_validate {
	position: relative;
}

.thank_you_wrap {
	margin-bottom: 30px;
}

.thank_you_wrap .fa {
	font-size: 100px;
	color: #0dd1fb;
}

.en_flag {
	margin-left: 10px;
}

.contact_option {
	margin-top: 40px;
}

.contact_option dl a {
	color: #636363 !important;
	text-decoration: none !important;
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.contact_option dl a:hover {
	color: #0dd1fb !important;
}

.contact_option .dl-horizontal dt {
	width: 90px;
}

.contact_option .dl-horizontal dd {
	margin-left: 110px;
}

.file_div {
	margin-bottom: 25px;
}

.calculator_result {
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.ajax_loader {
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    right: 160px;
    top: 0;
}

.opacity_0 {
	opacity: 0 !important;
}

.opacity_1 {
	opacity: 1 !important;
}

#floatingCirclesG{
	position:relative;
	width:45px;
	height:45px;
	margin:auto;
	transform:scale(0.6);
		-o-transform:scale(0.6);
		-ms-transform:scale(0.6);
		-webkit-transform:scale(0.6);
		-moz-transform:scale(0.6);
}

.f_circleG{
	position:absolute;
	background-color:rgb(255,255,255);
	height:8px;
	width:8px;
	border-radius:4px;
		-o-border-radius:4px;
		-ms-border-radius:4px;
		-webkit-border-radius:4px;
		-moz-border-radius:4px;
	animation-name:f_fadeG;
		-o-animation-name:f_fadeG;
		-ms-animation-name:f_fadeG;
		-webkit-animation-name:f_fadeG;
		-moz-animation-name:f_fadeG;
	animation-duration:1.2s;
		-o-animation-duration:1.2s;
		-ms-animation-duration:1.2s;
		-webkit-animation-duration:1.2s;
		-moz-animation-duration:1.2s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}

#frotateG_01{
	left:0;
	top:18px;
	animation-delay:0.45s;
		-o-animation-delay:0.45s;
		-ms-animation-delay:0.45s;
		-webkit-animation-delay:0.45s;
		-moz-animation-delay:0.45s;
}

#frotateG_02{
	left:5px;
	top:5px;
	animation-delay:0.6s;
		-o-animation-delay:0.6s;
		-ms-animation-delay:0.6s;
		-webkit-animation-delay:0.6s;
		-moz-animation-delay:0.6s;
}

#frotateG_03{
	left:18px;
	top:0;
	animation-delay:0.75s;
		-o-animation-delay:0.75s;
		-ms-animation-delay:0.75s;
		-webkit-animation-delay:0.75s;
		-moz-animation-delay:0.75s;
}

#frotateG_04{
	right:5px;
	top:5px;
	animation-delay:0.9s;
		-o-animation-delay:0.9s;
		-ms-animation-delay:0.9s;
		-webkit-animation-delay:0.9s;
		-moz-animation-delay:0.9s;
}

#frotateG_05{
	right:0;
	top:18px;
	animation-delay:1.05s;
		-o-animation-delay:1.05s;
		-ms-animation-delay:1.05s;
		-webkit-animation-delay:1.05s;
		-moz-animation-delay:1.05s;
}

#frotateG_06{
	right:5px;
	bottom:5px;
	animation-delay:1.2s;
		-o-animation-delay:1.2s;
		-ms-animation-delay:1.2s;
		-webkit-animation-delay:1.2s;
		-moz-animation-delay:1.2s;
}

#frotateG_07{
	left:18px;
	bottom:0;
	animation-delay:1.35s;
		-o-animation-delay:1.35s;
		-ms-animation-delay:1.35s;
		-webkit-animation-delay:1.35s;
		-moz-animation-delay:1.35s;
}

#frotateG_08{
	left:5px;
	bottom:5px;
	animation-delay:1.5s;
		-o-animation-delay:1.5s;
		-ms-animation-delay:1.5s;
		-webkit-animation-delay:1.5s;
		-moz-animation-delay:1.5s;
}



@keyframes f_fadeG{
	0%{
		background-color:rgb(99,99,99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-o-keyframes f_fadeG{
	0%{
		background-color:rgb(99,99,99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-ms-keyframes f_fadeG{
	0%{
		background-color:rgb(99,99,99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-webkit-keyframes f_fadeG{
	0%{
		background-color:rgb(99,99,99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-moz-keyframes f_fadeG{
	0%{
		background-color:rgb(99,99,99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

.calculator_result {
	font-family: "Oswald",sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 30px;
    padding: 0;
    text-transform: uppercase;
}

.home-slider {
	padding: 0 !important;
}

.slide > .row {
	margin: 0 !important;
	position: relative;
	z-index: 50;
}

.slide {
	height: 400px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	position: relative;
}

.slide_title {
	color: #fff;
	/*text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);*/
}

.slide_text {
	color: #fff;
	/*text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);*/
}

.slide_gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 70%;
	height: 100%;
	background: url('../images/slide_gradient.png') repeat-y 0 0;
	background-size: 100% auto;
}

.home-slider .bx-pager {
	bottom: 10px !important;
}

.home-slider .bx-pager .bx-pager-link {
	background: #fff !important;
	-webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.home-slider .bx-pager .active, .home-slider .bx-pager .bx-pager-link:hover {
	background: #0dd1fb !important;
}

.testimonials {
	background: url('../images/quote.png') no-repeat center 20px #9eabb2;
	background-size: 38px 30px;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}

.testimonials_inner {
	padding: 75px 25px 30px 25px;
}

.testimonial_text {
	font-style: italic;
	margin-bottom: 15px;
	color: #fff;
}

.testimonial_name {
	font-size: 13px;
	color: #fff;
}

.border_div {
	height: 1px;
	background: #9eabb2;
	margin: 0 0 50px 0;
}

.cta_li {
	margin-bottom: 50px;
}

.cta_title {
	font-family: "Oswald",sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 10px 0;
    padding: 0;
}

.cta_icon {
	font-size: 35px;
	height: 50px;
	margin-bottom: 15px;
	color: #0dd1fb;
}

.footer_form_row {
	position: relative;
}

.footer_form_row .parsley-errors-list {
	top: 10px !important;
	right: 10px !important;
}

.about_image {
	float: right;
	margin-left: 15px;
	max-width: 300px;
}

.price_to_pay {
	font-size: 20px;
	margin-bottom: 30px;
}

.price_span, .price_span_currency {
	font-size: 20px;
	font-family: "Oswald",sans-serif;
    font-weight: 600;
}

.additional_prices {
	color: #0dd1fb;
	font-weight: 700;
	cursor: pointer;
}

#calculator_form input[name=retur], .additional_cost {
	margin-bottom: 25px;
}