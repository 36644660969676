/*!
 * SlickNav Responsive Mobile Menu v1.0.3
 * (c) 2015 Josh Cope
 * licensed under MIT
 */
.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-top: 6px; }
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    margin: 0.188em 0 0 0.438em; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    height: 2px;
    margin-bottom: 5px;
    width: 22px; }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: none;
  height: 1px;
  position: absolute;
  top: 41px;
  width: 100%;
  z-index: 600000;.slicknav_nav
}
  .slicknav_menu * {
    box-sizing: border-box; }
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 3px #000; }
  .slicknav_menu .slicknav_icon-bar {
    background-color: #0dd1fb; }

.slicknav_btn {
  margin: 28px 30px 6px 0;
}

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden;
  width: 90%;
  margin: 75px auto 0;
  background: #0dd1fb;
  text-align: center; }
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0 0 0 20px; }
  .slicknav_nav .slicknav_row {
    padding: 5px 10px;
    margin: 2px 5px; }
    .slicknav_nav .slicknav_row:hover {
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      background: #fff;
      color: #0dd1fb; }
  .slicknav_nav a {
    padding: 5px 10px;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff; }
    .slicknav_nav a:hover {
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      background: #fff;
      color: #0dd1fb; }
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; }
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; }
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; }

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; }
